
.formGroup{
    display: flex;
    align-items: center;
    /* width: 100%; */
    justify-content: space-between;
    margin: 1rem;
}

.formControl{
    width: 75%;
    border: 1px solid black;
}